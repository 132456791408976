<template>
  <div>
    <!--   -->
    <div
      v-if="companyConfig.pcgametype == 1"
      :class="[{ wan: isFinished }, className1]"
      @animationstart="jianting"
      @animationend="hideBox"
      :data-attr="data_attr"
    >
      <router-view></router-view>
    </div>
    <div v-if="companyConfig.pcgametype == 2" class="liuhe" :class="className">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  data() {
    return {
      className: "",
      className1: "",
      data_attr: "",
      isFinished: false,
    };
  },
  watch: {
    currentGame: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!this.IsPhone()) {
          if (this.companyConfig.pcgametype == 1) {
            if (val.Gametype == "hk6") {
              this.isFinished = false;
              let roomeng = val.roomeng;
              if (roomeng == "amlh") {
                this.data_attr = "澳门六";
                this.className1 = "am6";
              } else if (roomeng == "hk6") {
                this.data_attr = "香港六";
                this.className1 = "xg6";
              } else if (roomeng == "twdlt") {
                this.data_attr = "台湾六";
                this.className1 = "tw6";
              } else if (roomeng == "xamlhc") {
                this.data_attr = "新澳六";
                this.className1 = "xa6";
              } else if (roomeng == "tchx6") {
                this.data_attr = "中体六";
                this.className1 = "zt6";
              } else if (roomeng == "bingo6j1") {
                this.data_attr = "宾果六";
                this.className1 = "bg6";
              } else {
                this.data_attr = "";
                this.className1 = "";
              }
            } else {
              this.data_attr = "";
              this.className1 = "";
            }
          }
          if (this.companyConfig.pcgametype == 2) {
            let roomeng = val.roomeng;

            //  bingo6j1        tchx6
            if (roomeng == "amlh") {
              this.className = "aomenbg";
            } else if (roomeng == "hk6") {
              this.className = "xianggangbg";
            } else if (roomeng == "twdlt") {
              this.className = "taiwanbg";
            } else if (roomeng == "xamlhc") {
              this.className = "xinaobg";
            } else if (roomeng == "bingo6j1") {
              this.className = "bglbg";
            } else if (roomeng == "tchx6") {
              this.className = "ztlbg";
            } else {
              this.className = "";
            }
          } else {
            this.className = "";
          }
        }
      },
    },
  },
  // ''''''
  computed: {
    ...mapState(["currentGame", "companyConfig"]),
    // 快选金额数据 quickSelectedData
    quickSelectedData() {
      return this.$store.state.quickSelectedData;
    },
    quick_c() {
      return this.$store.state.quick_c;
    },
    //是否启用 快选金额
    quick_b() {
      return this.$store.state.quick_b;
    },
  },
  components: {},
  created() {},
  methods: {
    IsPhone() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent;
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone =
        /mobile|phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(
          info
        );
      //如果包含“Mobile”（是手机设备）则返回true
      return isPhone;
    },
    jianting() {
      this.isFinished = false;
    },
    hideBox() {
      this.isFinished = true;
    },
  },
};
</script>
<style>
/* --zongti */
.zt6.wan:before {
  z-index: -1;
  display: none;
}
.zt6:before {
  animation: zia 1.5s ease-in-out;
  animation-iteration-count: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 80px;
  right: 276px;
  bottom: 0;
  z-index: 1;
  content: url("../assets/zt6.png");
}
/* --xinao */
.xa6.wan:before {
  z-index: -1;
  display: none;
}
.xa6:before {
  animation: zia 1.5s ease-in-out;
  animation-iteration-count: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 80px;
  right: 276px;
  bottom: 0;
  z-index: 1;
  content: url("../assets/xa6.png");
}
/* --taiwan */
.tw6.wan:before {
  z-index: -1;
  display: none;
}
.tw6:before {
  animation: zia 1.5s ease-in-out;
  animation-iteration-count: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 80px;
  right: 276px;
  bottom: 0;
  z-index: 1;
  content: url("../assets/tw6.png");
}
/* --xianggang */
.xg6.wan:before {
  z-index: -1;
  display: none;
}
.xg6:before {
  animation: zia 1.5s ease-in-out;
  animation-iteration-count: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 80px;
  right: 276px;
  bottom: 0;
  z-index: 1;
  content: url("../assets/xg6.png");
}
/* aomen */
.am6.wan:before {
  z-index: -1;
  display: none;
}
.am6:before {
  animation: zia 1.5s ease-in-out;
  animation-iteration-count: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 80px;
  right: 276px;
  bottom: 0;
  z-index: 1;
  content: url("../assets/am6.png");
}
/* bingguo6 */
.bg6.wan:before {
  z-index: -1;
  display: none;
}
.bg6:before {
  animation: zia 1.5s ease-in-out;
  animation-iteration-count: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 80px;
  right: 276px;
  bottom: 0;
  z-index: 1;
  content: url("../assets/bg6.png");
}
/* --------字体还是没完成 */
.liuhe {
  position: relative;
}
.liuhe.wan:before {
  z-index: -1;
  display: none;
}
.liuhe:before {
  animation: zia 1.5s ease-in-out;
  animation-iteration-count: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 80px;
  right: 276px;
  bottom: 0;
  z-index: 1;
  /* content: attr(data-attr); */

  /* font-size: 250px; */

  /* color: #e0e0e0; */
  /* font-family: "青鸟华光简彩云",sans-serif; */
}
/* ----------------------------- */
@keyframes zia {
  0% {
    /* transform: scale(0); */
    opacity: 1;
  }
  50% {
    /* transform: scale(1); */
    opacity: 1;
  }
  100% {
    /* transform: scale(0); */
    opacity: 0;
  }
}
</style>
